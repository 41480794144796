import React from "react";
import "./Simplyfy.scss"; 
import background from '../../assets/images/bg-image.svg';
import checkedIcon from '../../assets/images/checked.svg';

const Simplyfy = () => {
  return (
    <div id="head">
      <div className="centered-container d-flex align-items-center justify-content-center">
        <div className="text-center">
          <h1 className="centered-heading">Make every email count with <br /> MessageCloud.AI!</h1>
        </div>
      </div>
      <div className="simplify-content">
        <div className="text-content">
          <h2 className="content-heading">Simplify Your Critical Email <br/>Campaigns With MessageCloud.AI</h2>
          
          <div className="key-benefits">
            <img src={checkedIcon} alt="Checked Icon" className="icon-image" />
            <p>Navigating the complexities of building and sending critical bulk emails can take time and effort. MessageCloud.AI simplifies this process, allowing businesses to start large-scale email campaigns within minutes.</p>
          </div>

          <div className="key-benefits">
            <img src={checkedIcon} alt="Checked Icon" className="icon-image" />
            <p>Our email delivery system harnesses advanced infrastructure built by experienced tech professionals. We offer a unique solution that eliminates complexities and optimizes your email delivery strategy. At MessageCloud.AI, we have built a fast and reliable email delivery system that offers high email deliverability generating maximum ROI for your business.</p>
          </div>
        </div>

        <div className="image-content">
          <img src={background} alt="Image" className="centered-image" />
        </div>
      </div>
      </div>
  );
};

export default Simplyfy;
