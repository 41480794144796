import React from "react";
import "./style.scss";
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';
import Accordion from 'react-bootstrap/Accordion';

const WorkWithUs = () => {
    return (
        <div className="work-with-us">
            <NavBar />
            <div className="centered-container align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="centered-heading">
                        Join MessageCloud.AI and Be a Catalyst for <br className="display-property" /> Tomorrow's Communication Evolution
                    </h1>
                </div>
            </div>
            <div class="projects_section">
                <div class="row g-0">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                        <div class="project_card">
                            <span class="service_title">Make An <br />Impact</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                        <div class="project_card project_card_col1">
                            <span class="service_title">Introduce passion with purpose</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                        <div class="project_card project_card_col2">
                            <span class="service_title">Achieve Unified Success</span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                        <div class="project_card project_card_col3">
                            <span class="service_title">Unleash Your Curiosity</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="go-getter-section space-align">
                <h1>Are You a Go-Getter? <br />We Might Be Looking For You!</h1>
                <p>Welcome to MessageCloud.AI, where innovation meets ambition. We're on a mission to redefine email communication for enterprises. And guess what? We're looking for go-getters; individuals who thrive on challenges and envision themselves as the industry leaders of the future to join us in our journey towards excellence. At MessageCloud.AI, you get to connect with leading enterprises and strengthen your industry networks. This is not just a job; it's an opportunity to shape the future of communication.</p>
                <p className="mt-4">Our culture is vibrant, collaborative, and driven by a shared passion for excellence. We believe in giving everyone the opportunity they need to build a rewarding career within the enterprise ecosystem.</p>
            </div>
            <div className="openings space-align">
                <h1>Job Openings</h1>
                <div className="row">
                    <div className="col-lg-12">
                        <Accordion className="job-openings" defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className="row w-100">
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                            <div className="basic-info">
                                                <span>Role</span>
                                                <span className="d-block answers">Business Development Executive</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div className="basic-info">
                                                <span>Experience</span>
                                                <span className="d-block answers">Fresher/Experienced</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div className="basic-info  mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                <span>Vacancies</span>
                                                <span className="d-block answers">Fresher/Experienced</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                                            <div className="basic-info  mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                <span>Location</span>
                                                <span className="d-block answers">Delhi</span>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="p-0 text-start">
                                    <button className="designation-tab">Business Development Executive</button>
                                    <div className="key-points pb-0">
                                        <h3>Roles & Responsibilities</h3>
                                    <ul className="mb-4">
                                        <li>Client Acquisition:<span className="d-block">Engage potential clients, showcasing the benefits of MessageCloud.AI for their email delivery needs.</span></li>
                                        <li>Sales Pipeline Management:<span className="d-block">Develop and manage a dynamic sales pipeline, ensuring lead progression to conversion.</span></li>
                                        <li>Industry Engagement:<span className="d-block">Stay informed about industry trends and opportunities for business expansion.</span></li>
                                        <li>Client Relationship Building:<span className="d-block">Build strong client relationships, understanding unique requirements and optimizing MessageCloud.AI services.</span></li>
                                        <li>Product Presentations:<span className="d-block">Deliver compelling sales presentations, demonstrating the value of MessageCloud.AI to clients.</span></li>
                                        <li>Collaboration:<span className="d-block">Collaborate with internal teams to align sales strategies.</span></li>
                                        </ul>
                                    </div>
                                    <div className="key-points">
                                        <h3>Requirements:</h3>
                                        <ul className="mt-4">
                                            <li className="pt-0"><span className="d-block">A Bachelors/Masters degree in Business or other relevant field.</span></li>
                                            <li className="pt-0"><span className="d-block">Proven experience as a Sales Executive in technology or SaaS.</span></li>
                                            <li className="pt-0"><span className="d-block">Understanding of email communication technology. </span></li>
                                            <li className="pt-0"><span className="d-block">Strong communication and negotiation skills.</span></li>
                                        </ul>
                                        <button className="apply-btn">Apply</button>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default WorkWithUs;