import './App.scss';
import LandingPage from './pages/LandingPage/LandingPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from '../src/components/Products/Products';
import ContactSales from './pages/ContactSales/ContactSales';
import QuickStart from './pages/QuickStart';
import WorkWithUs from './pages/WorkWithUs';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
      <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/contact-us" element={<ContactSales />} />
          <Route path="/quick-start" element={<QuickStart />} />
          <Route path="/work-with-us" element={<WorkWithUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
