import React from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './ContactSales.scss'
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';

const ContactSales = () => {
  return (
    <>
      <NavBar />
    <div className='div-main'>

      <div className='contactS'><h2 className='Heading'>Contact Us</h2>
          <p className='text'>We would love to know about your email requirements. <label className='d-block text-center'>Fill in your details and we'll contact your shortly!</label></p>
      </div>

      <div className='form'>
        <form id='style'>
            <h1 className='form-heading'>Fill your details here!</h1>
            <div className='form_row'>
              <div className='col_tab'>
                <label className='first w-100 d-block' htmlFor="firstName">First name <span>*</span></label>
                <input type="text" placeholder='First Name' className="firstname" name="firstName" required />
              </div>
              <div className='col_tab'>
                <label className='w-100 d-block' id='last' htmlFor="lastName">Last name<span>*</span></label>
                <input type="text" className="lastname" name="lastname" placeholder='Last Name' required />
              </div>
            </div>
            <div className='form_row'>
              <div className='col_tab'>
                <label className='first w-100 d-block' htmlFor="firstName">Your Work Email Address<span>*</span></label>
                <input type="email" placeholder='nisha@gmail.com' className="firstname" name="email" required />
              </div>
              <div className='col_tab'>
                <label className='w-100 d-block' id='number' htmlFor="phoneNumber">Your phone number<span>*</span></label>
                <PhoneInput id='phoneInput' className="phone-input"
                  inputProps={{
                    name: 'phoneNumber',
                    required: true,
                  }}
                />
              </div>
            </div>
            <div className='form_row'>
              <div className='col_tab'>
                <label className='first w-100 d-block' htmlFor="companyName">Your company name<span>*</span></label>
                <input type="text" placeholder="Comapny Name" className="firstname" name="companyName" required />
              </div>
              <div className='col_tab'>
                <label className='w-100 d-block' id='role' htmlFor="role">Your role<span>*</span></label>
                <input type="text" className="lastname" name="lastname" placeholder='Enter Your Role' required />
              </div>
            </div>
            <div className='form_row'>
              <div className='col_tab'>
                <label className='first w-100 d-block' htmlFor="CompanySize">Company Size <span>*</span></label>
                <select className="CompanySize" name="companySize" required>
                  <option value="">Select one</option>
                  <option value="1-9">1-9</option>
                  <option value="10-49">10-49</option>
                  <option value="50-99">50-99</option>
                  <option value="100-249">100-249</option>
                  <option value="249-499">249-499</option>
                  <option value="500-999">500-999</option>
                  <option value="1000">1000</option>
                </select>
              </div>
              <div className='col_tab'>
                <label className='w-100 d-block' htmlFor="country">Country <span>*</span></label>
                <select id="countrySelect" name="country" required>
                  <option value="">Select a country</option>
                  <option value="India">India</option>
                </select>
              </div>
            </div>
            <div className='form_row'>
              <div className='col_tab description-text'>
                <label className='w-100 d-block' id='role' htmlFor="role">How can we Help?<span>*</span></label>
                <textarea type="text" placeholder='Type Here ...' required />
              </div>
            </div>

          <button className='submit-button' type="submit">Submit</button>
        </form>
      </div>
      </div>
      <Footer />
      </>
  );
}

export default ContactSales;