import React from "react";
import "./style.scss";
// import EmployePicture from "../../assets/employee.svg"
// import EmployePicturesecond from "../../assets/kushal.png"
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';

const AboutUs = () => {
    return (
        <div className="work-with-us">
            <NavBar />
            <div className="centered-container align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="centered-heading">
                        Fast & Reliable Email Delivery System
                    </h1>
                </div>
            </div>
            <div className="go-getter-section space-align">
                <h1>Who We Are?</h1>
                <p>MessageCloud.AI is a robust and reliable email delivery system committed to delivering seamless email solutions. Backed by easy and scalable APIs along with dedicated support, our platform is built to cater to businesses of all sizes.</p>
                <p className="mt-4">Built by the leading industry veterans with over a decade of experience in the Indian email Industry, we understand the nuances of customers in regards to email communication. In the past we have successfully navigated Octane to become India’s leading permission- based email marketing platform in India delivering over 10 billion successful emails across various sectors.</p>
                <p className="mt-4">We are committed to continuously strive for high inbox delivery, manage high volume so that our clients could engage and nurture their customers for any business use case through emails.</p>
            </div>
            {/* <div className="our-team space-align">
                <h1>Our Team</h1>
                <p className="text-center">
                    Meet Our Tech Team Members: The driving force behind MessageCloud.AI's innovation, <br /> passion, and exceptional email delivery solutions.
                </p>
                <div className="team-gallary">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="team-card">
                                <div className="flex-change">
                                    <div className="team-picture">
                                        <img src={EmployePicture} alt="employee-picture" />
                                    </div>
                                    <div className="basic-intro">
                                        <h2>Digvijay Bhandari</h2>
                                        <span>UI/UX Designer</span>
                                    </div>
                                </div>
                                <p>
                                    Online Entrepreneur in India; Have built innovative models of customer engagement that creates tremendous business value.
                                    Co-FoundedIndia’s first permission based email marketing company, Octane, which served more than 200 enterprises across various segments in India in the last 10 years. Acquired by ValueFirst in 2017, one of India’s top messaging companies.
                                    Have a strong consumer behavior understanding which is the genesis of Octane’s robust platform for 1:1 targeted communication.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="team-card">
                                <div className="flex-change">
                                    <div className="team-picture">
                                        <img src={EmployePicturesecond} alt="employee-picture" />
                                    </div>
                                    <div className="basic-intro">
                                        <h2>Kushal Likhi</h2>
                                        <span>UI/UX Designer</span>
                                    </div>
                                </div>
                                <p>
                                    Kushal is a driving force behind <a>MessageCloud.AI.</a> With over <span>15 years of experience</span> in the industry, he specializes in scalable software solutions, guiding products from conception to widespread adoption. His expertise spans various domains like Transport-Logistics, Ed-Tech, Fin-Tech, and Health-Tech, where he champions emerging technologies and community growth. Kushal's track record includes optimizing technology solutions for maximum ROI, pioneering <span>AI and Machine Learning</span> solutions for companies like American Express, and leading the establishment of the NodeJS practice at <span>ToTheNew.</span> He's played pivotal roles in projects for <span>Uber, PVR, ClassPlus, and BMW, </span> leveraging deep analytical insights to drive success.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="team-card">
                                <div className="flex-change">
                                    <div className="team-picture">
                                        <img src={EmployePicture} alt="employee-picture" />
                                    </div>
                                    <div className="basic-intro">
                                        <h2>Vijayant</h2>
                                        <span>UI/UX Designer</span>
                                    </div>
                                </div>
                                <p>
                                    17+ years in leadership in MarTech, CPaaS and SaaS businesses. Impactful experience in managing P&L, GTM, Sales and Client Success teams in India & South Asia for companies like MessageBird, ValueFirst, Cheetah Digital, Group M, Octane and Policybazaar.com. Vijayant is an active early-stage investor in companies like GoMechaninc, MyFinFi, Rock Paper Rum and a few more, working closely with the foundrs to help them scale and grow.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="team-card">
                                <div className="flex-change">
                                    <div className="team-picture">
                                        <img src={EmployePicture} alt="employee-picture" />
                                    </div>
                                    <div className="basic-intro">
                                        <h2>Nisha Bhatt</h2>
                                        <span>UI/UX Designer</span>
                                    </div>
                                </div>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </div>
    );
};

export default AboutUs;