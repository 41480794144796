import React, { useState, useEffect, useRef } from "react";
import "./Products.scss";
import Triangle from '../../assets/images/traingle.svg';
import InvertedTriangle from '../../assets/images/Vector (2).svg';
import Simplyfy from "../Simplyfy/Simplyfy.js";
import Sticky from 'react-sticky-el';
// import Sticky from 'react-stickynode';
import NavBar from '../Navbar/NavBar.js';
import Footer from '../Footer/Footer.js';

const Products = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    scrollToElement(item);
    setSelectedItem(item);
  };

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = (e) => {
    const stickyElement = document.querySelector('.sidebar div');
    const container = document.querySelector('.sidebar+div');
    const topContainer = document.querySelector('.simplify-content');
    const scrollTop = window.scrollY;
    console.log(topContainer?.scrollHeight, container?.offsetHeight , stickyElement?.scrollHeight, scrollTop);
    if ((container?.offsetHeight + stickyElement?.scrollHeight -150 ) < scrollTop || topContainer?.scrollHeight +280 > scrollTop) {
      stickyElement.style.position = 'relative';
    } else if (topContainer?.scrollHeight < scrollTop || ((container?.offsetHeight + stickyElement?.scrollHeight) > scrollTop)){
      stickyElement.style.position = 'fixed';
    }
  };
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });

      const lis = document.querySelectorAll('.sidebar li');
      lis.forEach((li) => {
        li.classList.remove('active');
      });
      const activeLi = document.querySelector(`.sidebar li[data-id="${elementId}"]`);
      if (activeLi) {
        activeLi.classList.add('active');
      }
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <NavBar />
      <Simplyfy />
      <div className="features">
        <Sticky className="sidebar">
          <h3 className="features-heading">Features</h3>
          <ul className="p-0">
            <li
              onClick={() => handleItemClick('authentication')}
              className={selectedItem === 'authentication' ? 'active' : ''}
              data-id="authentication"
            >
              Authentication
            </li>
            <hr className="line-through"></hr>
            <li
              onClick={() => handleItemClick('sending-analytics')}
              className={selectedItem === 'sending-analytics' ? 'active' : ''}
              data-id="sending-analytics"
            >
              Sending Analytics
            </li>
            <hr className="line-through"></hr>
            <li
              onClick={() => handleItemClick('api-smtp')}
              className={selectedItem === 'api-smtp' ? 'active' : ''}
              data-id="api-smtp"
            >
              API and SMTP Service
            </li>
            <hr className="line-through"></hr>
            <li
              onClick={() => handleItemClick('data-sanitization')}
              className={selectedItem === 'data-sanitization' ? 'active' : ''}
              data-id="data-sanitization"
            >
              Data Sanitization
            </li>
            <hr></hr>
            <li
              onClick={() => handleItemClick('reputation-management')}
              className={selectedItem === 'reputation-management' ? 'active' : ''}
              data-id="reputation-management"
            >
              Reputation Management
            </li>
          </ul>
        </Sticky>
        <div className="content">
          <div className="content-item" id="authentication">
            <div className="content-text">
              <h2 className="content-heading m-0">
                Authentication Mechanisms Industry-Standard
              </h2>
              <p className="context-data">
                Message Cloud AI incorporates industry-standardized authentication
                protocols, including DomainKeys Identified Mail (DKIM), Sender
                Policy Framework (SPF), and Domain-based Message Authentication,
                Reporting, and Conformance (DMARC). These mechanisms establish a
                secure framework for validating the authenticity of a legitimate
                sender and enhance the sender's reputation.
              </p>
            </div>
          </div>
          <div className="content-item" id="sending-analytics">
            <div className="content-text">
              <h2 className="content-heading m-0">Sending Analytics</h2>
              <p className="context-data">
                With MessageCloud.AI, you can keep track of your email-sending
                activities. The system records the complete email response funnel,
                including the number of sends, deliveries, opens, clicks, bounces,
                complaints, and rejections. For instance: After sending 1 million
                emails, our system seamlessly captures and records deliverability
                data, including opens, hard and soft bounces, and spam marks. This
                intelligent feature ensures that future campaigns exclude
                problematic addresses, optimizing your email strategy for improved
                engagement and sender reputation.
              </p>
            </div>
          </div>
          <div className="content-item" id="api-smtp">
            <div className="content-text">
              <div className="dropdown">
                <h2 className="content-heading m-0">
                  Scalable API and SMTP service
                </h2>
                <div className="dropdown-button-container">
                  <img
                    className="triangle-image"
                    src={isDropdownOpen ? InvertedTriangle : Triangle}
                    alt={isDropdownOpen ? 'Inverted' : 'Triangle'}
                    onClick={toggleDropdown}
                  />
                </div>

              </div>

              <p className="context-data">
                The combination of our scalable and robust API and SMTP  services
                ensures a comprehensive solution, catering to diverse
                communication preferences. Whether you seek programmable
                communication through APIs or the reliability of SMTP,
                MessageCloud.AI offers a unified platform to optimize your email
                delivery experience.
              </p>
              {isDropdownOpen && (
                <div className="dropdown-content">
                  <div className="heading-container">
                    <h3 className="dropdown-headings">
                      <span className="numbers">01</span> One-to-One API
                      (Transactional API)
                    </h3>
                  </div>
                  <p>
                    MessageCloud.AI's one-to-one API i.e Transactional API swiftly
                    delivers critical event-based messages like order
                    confirmations and account notifications. With seamless
                    integration, developers enhance the user experience through
                    real-time event-triggered emails, dynamic content
                    personalization, and instant delivery confirmation.
                  </p>
                  <hr className="horizontal"></hr>
                  <div className="heading-container">
                    <h3 className="dropdown-headings">
                      {" "}
                      <span className="numbers">02</span>Batch API (Transactional
                      API)
                    </h3>
                  </div>
                  <p>
                    MessageCloud.AI's one-to-one API i.e Transactional API swiftly
                    delivers critical event-based messages like order
                    confirmations and account notifications. With seamless
                    integration, developers enhance the user experience through
                    real-time event-triggered emails, dynamic content
                    personalization, and instant delivery confirmation.
                  </p>
                  <hr className="horizontal"></hr>
                  <div className="heading-container">
                    <h3 className="dropdown-headings">
                      <span className="numbers">03</span> SMTP API
                    </h3>
                  </div>
                  <p>
                    For organizations who want comprehensive insights for their
                    email campaigns, MessageCloud.AI's SMTP API is ideal as it has
                    robust tracking capabilities. Its monitoring features for
                    opens, clicks, and engagement metrics give a trackable
                    solution to organizations craving valuable data for the
                    refinement and optimization of communication strategies.
                  </p>

                </div>
              )}
            </div>
          </div>

          <div className="content-item" id="data-sanitization">
            <div className="content-text">
              <h2 className="content-heading m-0">Data Sanitization</h2>
              <p className="context-data">
                Our advanced data sanitization capabilities seamlessly detects and
                eliminate duplicate data, such as repetitive email recipient
                addresses, while also identifying and rectifying invalid data
                points, including incorrect or invalid email recipient addresses.
                This meticulous approach ensures the cleanliness and accuracy of
                your dataset, laying a robust foundation for the reliable and
                critical task of sending emails with confidence.
              </p>
            </div>
          </div>
          <div className="content-item" id="reputation-management">
            <div className="content-text">
              <h2 className="content-heading m-0">
                {" "}
                Reputation Management & Auto-Warm Up
              </h2>
              <p className="context-data">
                A sender's reputation is crucial for email deliverability,
                affecting whether emails end up in recipients' inboxes or spam
                folders. Our auto-warm-up of IPs and Domains establishes the
                sender’s trust and credibility by providing the insights about the
                email campaign performance. These insights can be used by the
                sender to gradually increase emai volumes and reduce the chance of
                getting reported as spam.
              </p>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Products;
