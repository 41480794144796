import React from "react";
import "./style.scss";
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';

const Quickstart = () => {
    return (
        <>
            <NavBar />
            <div className="centered-container align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="centered-heading">
                        Welcome to MessageCloud.AI <br /> Your Fast and Reliable Email
                        Delivery Partner
                    </h1>
                </div>
            </div>

            <div className="card-container">
                <div className="card">
                    <div className="card-body p-0">
                        <h5 className="card-text-title thingstoknow">
                            Things You Should <br className="display-break" /> Know Before Getting <br className="display-break" />
                            Started
                        </h5>
                    </div>
                </div>

                <div className="card card2">
                    <div className="card-body p-0">
                        <h5 className="card-text-title ">
                            Self-Configuration & Default Settings
                        </h5>
                        <p className="card-text-description">
                            MessageCloud offers its users the option to self-configure and
                            send a limited number of emails (100/day) under the default
                            settings.
                        </p>
                    </div>
                </div>

                <div className="card card3">
                    <div className="card-body p-0">
                        <h5 className="card-text-title">About Dashboard Application</h5>
                        <p className="card-text-description">
                            Within the MessageCloud.AI dashboard application, we offer a
                            user-friendly feature that allows you to create a Sender Identity
                            with ease, incorporating essential elements such as DKIM, SPF, and
                            DMARC, along with other necessary DNS setups. This ensures a
                            secure and reliable foundation before sending any email or
                            campaign.
                        </p>
                    </div>
                </div>

                <div className="card card4">
                    <div className="card-body p-0">
                        <h5 className="card-text-title">What is Sender Identity?</h5>
                        <p className="card-text-description">
                            Within the MessageCloud.AI dashboard application, we offer a
                            user-friendly feature that allows you to create a Sender Identity
                            with ease, incorporating essential elements such as DKIM, SPF, and
                            DMARC, along with other necessary DNS setups. This ensures a
                            secure and reliable foundation before sending any email or
                            campaign.
                        </p>
                    </div>
                </div>
            </div>
            <div className="quick-setup">
                <h2>Quick Steps For Sender Identity Set-Up</h2>
                <div className="step-content">
                    <div className="step-container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                                <div className="step-number">Step 1</div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-8 col-8">
                                <div className="step-content">
                                    <h3>Add Your Domain</h3>
                                    <p>Begin by adding the domain you wish to use as your sending domain. For example- If your domain is "example.com", you would add this domain and authenticate to start the sender identity set-up.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                                <div className="step-number">Step 2</div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-8 col-8">
                                <div className="step-content">
                                    <h3>DNS Setup</h3>
                                    <p>MessageCloud.AI dashboard application will automatically generate a series of TXT, MX, and CNAME records that you need to publish within the DNS (Domain Name System) of your sending domain.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                                <div className="step-number">Step 3</div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-8 col-8">
                                <div className="step-content">
                                    <h3>Verify Records</h3>
                                    <p>Once the series of TXT, MX and CNAME records are published within the DNS of your sending domain, you can click the "verify record" button displayed on our application. After successful verification of each record, a GREEN-colored icon will be displayed on our application.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                                <div className="step-number">Step 4</div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-8 col-8">
                                <div className="step-content">
                                    <h3>Get Your API Key</h3>
                                    <p>After verifying the records, you are all set to access your API key. Now, just click on 'GET API,' & you'll have the API key needed for integration with your CRM or for manually sending campaigns.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                                <div className="step-number">Step 5</div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-8 col-8">
                                <div className="step-content">
                                    <h3>Start Sending Emails</h3>
                                    <p>Once you authenticate the Sender Identity & obtain the API key, you can now use MessageCloud.AI's APIs to seamlessly integrate with your application or use the platform’s UI to manually send emails.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Quickstart;