import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="content-section">
        <div className="about">
          <h1>Company</h1>
          <ul className="footer-sub-menu">
            <li><Link className="footer-link" to="/about-us">About</Link></li>
          </ul>
        </div>
        <div className="quick-links">
          <h1><Link to="/products" className="footer-header-link">Product</Link></h1>
        </div>
        <div className="quick-links">
          <h1>Resources</h1>
          <ul className="footer-sub-menu">
            <li><Link to="/quick-start" className="footer-link" >Quick Start</Link></li>
          </ul>
        </div>
        <div className="quick-links">
          <h1><Link to="/work-with-us" className="footer-header-link">Work With Us</Link></h1>
        </div>
        <div className="contact-us">
          <h1>Drop us a line</h1>
          <p>
            Interested in learning more about us? We'd love to hear from you.
          </p>
          <div className="btn-container">
            <Link to="/contact-us"> <button>Contact Sales</button> </Link>
          </div>
        </div>
      </div>
      <div className="copyright-tnc">
          <span>Copyright 2024 MessageCloud.ai</span>
          <div className="tnc">
          <span><Link to="/terms-and-conditions">Terms & Conditions</Link></span>
          <span className="pe-0"><Link to="/privacy-policy">Privacy Policy</Link></span>
          </div>

      </div>
    </div>
  );
}

export default Footer;
