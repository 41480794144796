import React from "react";
import "./LandingPage.scss";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer/Footer";
import emailLogo from "../../assets/email-logo.png";
import delImg from "../../assets/del-img.svg"
import DynamicEmail from "../../assets/dynamic-email.svg"
import RealTime from "../../assets/real-time.svg"
import DeleiveryTracking from "../../assets/deleivery-tracking.svg"
import SupressionList from "../../assets/supression-list.svg"
import SecureAuthentication from "../../assets/secure-authentication.svg"
import AdvanceAutoscaling from "../../assets/advance-autoscaling.svg"
import RobustApi from "../../assets/robust-api.svg"
import EnhancedInbox from "../../assets/enhanced-inbox.svg"
import curve from "../../assets/curve.svg"
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

function LandingPage() {
  const prosArray = [
    {
      iconUrl: DynamicEmail,
      heading: "Dynamic Email Content Customization",
      content:
        "Deliver tailored emails to your user with dynamic content based on user data.",
    },
    {
      iconUrl: RealTime,
      heading: "Real-Time Event Triggers",
      content:
        "Automate triggers for quick email responses for real-time communication with your users.",
    },
    {
      iconUrl: DeleiveryTracking,
      heading: "Delivery Tracking & Analytics",
      content:
        "Check and monitor the email delivery status in real-time with MessageCloud.AI’s advanced analytics.",
    },
    {
      iconUrl: SupressionList,
      heading: "Suppression List",
      content:
        "Eliminate issues by blocking, sending and tracking for specific addresses with MessageCloud.AI's Suppression List.",
    },
    {
      iconUrl: SecureAuthentication,
      heading: "Secure Authentication & Verification",
      content:
        "Secure authentication methods for email recipients and verify user identity with multi-step verification processes.",
    },
    {
      iconUrl: RobustApi,
      heading: "Robust API Integration",
      content:
        "Easily Integrate our email delivery services with your existing systems for a seamless email sending experience.",
    },
    {
      iconUrl: AdvanceAutoscaling,
      heading: "Advanced Auto-Scaling ",
      content:
        "Scale your email delivery resources automatically as per need and handle spikes in email traffic without affecting the performance.",
    },
    {
      iconUrl: EnhancedInbox,
      heading: "Enhanced Inbox Deliverability Insights",
      content:
        "Track bounce rates, opens, and clicks within a user-friendly interface and optimize your email deliverability performance.",
    },
  ];
  return (
    <div className="landing-page">
      <div className="upper-section">
        <NavBar />
        <div className="heading">
          <h1>Fast & Reliable Email Delivery System</h1>
          <p className="para-1">
            Ensure High deliverability and make every email count<br className="display-property" />
            with MessageCloud.AI’s Robust Email Delivery System
          </p>
          <div className="activity-btn-container p-0">
            <Link to='/contact-us'>
              <button className="btn-1">Start Now</button>
            </Link>
            <Link to='/contact-us'><button className="btn-2">Contact Sales</button></Link>
          </div>
        </div>

        <div className="box">
          {/* <div className="logo-container">
            <img src={logoImg} alt="" ></img>
          </div> */}

          <div className="curve-img">
            <img src={curve} alt="" ></img>
          </div>

          <div className="box-items">
            <div className="img-container">
              <img src={emailLogo} alt="" className="img-1"></img>
            </div>
            <div className="box-content">
              <h2>
                2x Email ROI With
                MessageCloud.AI
              </h2>

              <p className="para-2">
                Unlock more revenue while keeping your email costs
                <br />in check with our proven email delivery solutions.
              </p>
              <Link to='/contact-us'>
                <button>Start Now</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Pros Of MessageCloud.AI’s  */}


      <div className="pros-section">
        <div className="pros-heading">
          <h1>Pros Of MessageCloud.AI’s<br />
            Email Delivery System</h1>
        </div>
        <div className="pros-boxes">

          {prosArray.map((pro, index) => (
            <div key={index} className="pros-box">
              <div className="header-content">
                <div className="header">
                  <div className="pros-image"><img src={pro.iconUrl} alt="Icon" className="pro-icon" /></div>
                  <h2 className="pros-card-heading">{pro.heading}</h2>
                </div>

                <p className="pros-content">{pro.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* delivery details */}

      <div className="delivery-details">
        <div className="delivery-content">
          <h1>Blazing Fast Delivery For Your Transactional Emails!</h1>
          <p>
            <FaCheckCircle color="#070585" className="icon" />
            Tired of waiting for your transactional emails to get delivered
            or worrying about them never reaching your inbox? Leave the
            email delivery hassles behind with MessageCloud.AI.
          </p>
          <p >
            <FaCheckCircle color="#070585" className="icon" />
            We boast of transactional email delivery up to 4x faster than
            the competition. No empty claims here – our real-time delivery
            metrics are right on our status page for you to track. Experience
            swift, reliable communication.
          </p>
        </div>
        <div className="delivery-boxes-container">
          <div className="delivery-box">
            <div className="box-1">
              <label>Gmail</label>
              <span className="bolder">3.09s</span>
              <label className="bold">Avg. 6.15s</label>
            </div>
            <div className="box-2">
              <label>Gmail</label>
              <span className="bolder">3.09s</span>
              <label className="bold">Avg. 6.15s</label>
            </div>

            <div className="box-3">
              <label>Gmail</label>
              <span className="bolder">3.09s</span>
              <label className="bold">Avg. 6.15s</label>
            </div>
            <div className="box-4">
              <label>Gmail</label>
              <span className="bolder">3.09s</span>
              <label className="bold">Avg. 6.15s</label>
            </div>
          </div>
        </div>
      </div>

      {/* why email section */}
      <div className="why-email-section">
        <div className="block">
          <div className="del-img-container"><img src={delImg} alt="" /></div>
        </div>
        {/* <div className="triangle"></div> */}
        <div className="content-box">
          <h1>Why Our Email Delivery System Stands Out?</h1>

          <p>
            <FaCheckCircle color="#070585" className="icon" />
            Deliver high-volume emails with the robust transactional email
            delivery
            system that has a proven track record of sending hundreds
            of billions of emails every year
          </p>




          <p>
            <FaCheckCircle color="#070585" className="icon" />
            Leverage advanced email deliverability capabilities to ensure that
            your emails land straight to your customers' inboxes.
          </p>


          <p>
            <FaCheckCircle color="#070585" className="icon" />
            Get high ROI at the best pricing plans designed for businesses
            relying <br /> on bulk emails.
          </p>
          <p>
            <FaCheckCircle color="#070585" className="icon" />
            Stay compliant with HIPAA-eligible, FedRAMP- and GDPR- and stay{" "}
            <br />
            trusted by your users.
          </p>
          <div className="start-now-container">
            <Link to='/contact-us'><button className="btn-1">Start Now</button></Link>
            <Link to="/contact-us"><button className="btn-2">Contact Sales</button></Link>
          </div>
        </div>
      </div>

      {/* API information section */}

      <div className="api-information-section">
        <div className="colored-block">
          <h1>
            MessageCloud.AI: Tailored APIs for Enterprises, Catering to Unique
            Email Needs.
          </h1>
          <p>
            MessageCloud.AI's transactional email delivery services offer 4
            types of API options tailored to the unique email demands of
            enterprises:
          </p>
        </div>
        <div className="boxes-block">
          <div className="box-1">
            <p>
              The <span className="api-bold">One-to-One API</span> for ensuring personalized communication through
              an event-based model. (suitable for sending account notifications,
              transaction confirmations, personalized updates etc.)
            </p>
          </div>
          <div className="box-2">
            <p>
              The <span className="api-bold">Batch API</span> streamlines the process of sending a single message
              to multiple recipients. (suitable for sending newsletters,
              promotional campaigns, announcements etc.)
            </p>
          </div>
          <div className="box-3">
            <p>
              For businesses wanting tracking insights, <span className="api-bold">SMTP API</span> provides
              trackable functionalities for detailed insights into email
              delivery status and engagement.
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}

      <Footer />

    </div>
  );
}

export default LandingPage;
