import React, { useState } from "react";
import "./NavBar.scss";
import logo from "../../assets/messageCloudLogo.svg";
import { Link } from "react-router-dom";
import DownArrow from "../../assets/downArrow.svg";
function NavBar() {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = () => {
    setIsOpen(true);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };
   
  return (
    <div>
      <div class="navbar-container">
        <div class="img">
          <Link to="/"><img src={logo} alt="Logo" /></Link> 
        </div>
        <div class="items">
          <span>
            <Link to="/products" className="link">Products</Link>
          </span>
          <span>
            <Link to="/about-us" className="link">About Us</Link>
          </span>
          <span className="dropdown position-relative" onClick={() => setShow(!show)}>Resources
            <img src={DownArrow} alt="arrow" />
            {show && <div id="dropdown-option" >
              <Link to="/quick-start">Quick Start</Link>
            </div>}
          </span>
          <span>
            <Link to="/work-with-us" className="link">Work With Us</Link>
          </span>
          <Link to="/contact-us"><button className="btn2">Contact Sales</button></Link>
          <Link to="/contact-us"><button className="btn1">Sign in</button></Link>
        </div>
        <div className="hemburger">
          <div className="hemburger-icon" onClick={openSidebar}>&#8801;</div>
        </div>
        {isOpen && (
          <div className="cloud-sidebar">
            <div
              className="sidebar-backdrop"
              tabIndex="0"
              role="button"
              aria-pressed="false"
              onClick={closeSidebar}
              onKeyDown={closeSidebar}
            />
            <div className="cloud-sidebar-inner">
              <div className="flex-flow">
                <Link to="/"><img className="mobile-logo" src={logo} alt="Logo" /></Link> 
                <button
                  type="button"
                  className="close-button"
                  onClick={closeSidebar}
                >
                  &#x2715;
                </button>
              </div>
              <div className="menu_lis_phone">
                <ul>
                  <li><Link to="/products" className="link">Products</Link></li>
                  <li><Link to="/about-us" className="link">About Us</Link></li>
                  <li><span className="dropdown" onClick={() => setShow(!show)}>Resources
                    <img src={DownArrow} alt="arrow" />
                    {show && <div id="dropdown-option" >
                      <Link to="/quick-start">Quick Start</Link>
                    </div>}
                  </span>
                  </li>
                  <li><Link to="/work-with-us" className="link">Work With Us</Link></li>
                  <li><Link to="/contact-us">Contact Sale</Link></li>
                  <li><Link to="/contact-us">Sign in</Link></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
